.App {
  text-align: center;
  height: 100vh;
  overflow: scroll;

}
html {
  touch-action: none;
  overflow: hidden;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.box1{
  background-image: url("./imgs/img1.png");
  width: 100%;
  height: 780px;
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  position: relative;
  /*z-index: -1;*/

}
.img{
  width: 100%;
  height: auto;

}
.img2{
  margin-top: -15px;
  z-index: 999;
  position: relative;
}
.img3{
  margin-top: -30px;
  z-index: -1;
}
.img4{
  margin-top: -30px;
}
.bottom{
  width: 100%;
  height: 60px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
  z-index: 999999;
}
.box{
  width: 92%;
  height: 280px;
  background: #fff;
  position: absolute;
  left: 4%;
  bottom: 30px;
  border-radius: 15px;
}
.line{
  display: flex;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
  height: 45px;
  justify-content: center;
  align-items: center;
}
.star{
  color: #FE0000;
}
.label{
  width: 100px;
  text-align: left;
}
.input{
  flex: 1;
  height: 30px;
  font-size: 16px;
}
.line1{
  margin-top: 20px;
}
.btn{
  width: 92%;
  margin-left: 4%;
  height: 45px;
  background: #f36527;
  line-height: 45px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-top: 40px;

  border-radius: 22px;
  position: relative;
}
.tips{
  height: 16px;
  background: #fb0102;
  font-size: 12px;
  line-height: 16px;
  padding: 5px;
  border-top-left-radius:10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  right: 0;
  top: -25px;
}